export const getSafetyScore = async (lat, lng) => {
  // Original API call code commented out
  /*
  const url = `https://sharp-lobster-eleos-13d1303c.koyeb.app/getscore/?lat=${lat}&lng=${lng}`;
  console.log("url being used", url);
  const response = await fetch(url);
  console.log("got passed response");
  const json = await response.json();
  console.log("json: ", json);
  return json;
  */
  
  return Number((Math.random() * 6 + 3).toFixed(1));
};
export const getRouteSafetyScore = async (latLngList) => {
  // Original API call code commented out
  /*
  const pointsArray = latLngList;
  const url = "https://sharp-lobster-eleos-13d1303c.koyeb.app/getroutescore/";
  console.log("url being used", url);
  console.log("object sending: ", pointsArray);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pointsArray),
  });
  console.log("called routes analysuis");
  console.log("Da response  was: ", response);
  const responseData = await response.json();
  console.log("response daata was: ", responseData);
  return responseData;
  */
  
  return Number((Math.random() * 6 + 3).toFixed(1));
};
